<template>
    <div class="page-break-inside-pdf">
      <footer-invoice-pdf v-if="dataFooter"
                          :inner-data.sync="innerData"
                          :is-banking-details.sync="dataFooter.is_banking_details"
                          :is-multi-language-label.sync="dataFooter.is_multi_language_label"
                          :is-signature.sync="dataFooter.is_signature"
      >

      </footer-invoice-pdf>
    </div>

</template>

<script>
import FooterInvoicePdf from "@/view/content/printing-footers/footerInvoicePdf.vue";

export default {
  name: "MainFooter",
  components: {
    FooterInvoicePdf,
  },
  props: {innerData: {}, dataFooter:{}},
  data() {
    return {
      template: [],
    }
  },

}
</script>

<style>
@import '~quill/dist/quill.core.css';
@media print {
  @page {
    size: a4;
    margin: 15px 0;
  }
}


div#invoice:not(.pdf) * {
  /*page-break-inside: avoid;*/
  /*page-break-before: auto;*/
}

.header-print {
  /*position: fixed;*/
  top: 20px;
  width: 754px;
}


.header-print-space {
  width: 754px;
  display: block;
  height: 150px;
}

.footer-print {
  /*position: fixed;*/
  bottom: 0;
  width: 754px;
  /*page-break-after:always;*/
}


div#invoice:not(.pdf) table.report-container {
  page-break-after: always;
}

table.report-container {
  text-align: unset !important;
}

thead.report-header {
  display: table-header-group;
}

tfoot.report-footer {
  display: table-footer-group;
}

thead.no-report-header {
  display: contents;
}

tfoot.no-report-footer {
  display: contents;
}

table thead.report-header tr th, table thead.no-report-header tr th {
  border: 0 !important;
}

.main {
  width: 199mm;
}

.header-info {
  /*margin-bottom: 150px;*/
}

.report-header-cell {
  /*padding-bottom: 20px;*/
  font-weight: unset !important;
}

.break-inside-revert, .break-inside-revert * {
  break-inside: revert !important;
}

.page-break-inside-pdf {
  page-break-inside: avoid;
}
</style>