<template>
  <div>
    <div v-if="isBankingDetails">
      <p class="mt-5 d-flex justify-content-between">
        <span>
          <span>التفاصيل المصرفية</span> <span v-if="isMultiLanguageLabel">(Bank Details)</span>
        </span>
        <span class="color-1a5a4a f-s-15px" v-if="innerData.company && innerData.company.iban">
        <span><span>الايبان</span> <span v-if="isMultiLanguageLabel"> (IBAN)</span></span> <span>:</span>
        <span> {{ innerData.company ? innerData.company.iban : '' }}</span>
      </span>
      </p>

      <div class="color-1a5a4a mb-0 f-s-15px d-flex justify-content-between text-center">

        <div v-if="innerData.company && innerData.company.account_holder_name">
          <span class="d-block"><span>اسم الحساب</span> <span v-if="isMultiLanguageLabel"> (Account Name)</span></span>
          <span class="d-block"> {{ innerData.company ? innerData.company.account_holder_name : '' }}</span>
        </div>

        <div v-if="innerData.company && innerData.company.bank_name">
          <span class="d-block"><span> البنك </span> <span v-if="isMultiLanguageLabel"> (Bank) </span></span>
          <span class="d-block"> {{ innerData.company ? innerData.company.bank_name : '' }} </span>
        </div>

        <div v-if="innerData.company && innerData.company.bank_branch">
          <span class="d-block"><span> الفرع  </span><span v-if="isMultiLanguageLabel"> (Branch) </span></span>
          <span class="d-block"> {{ innerData.company ? innerData.company.bank_branch : '' }} </span>
        </div>

        <div v-if="innerData.company && innerData.company.account_holder_number">
          <span class="d-block"><span>الحساب</span> <span v-if="isMultiLanguageLabel"> (Account)</span></span>
          <span class="d-block"> {{ innerData.company ? innerData.company.account_holder_number : '' }}</span>
        </div>


      </div>
    </div>


    <div v-if="isSignature" class="d-flex justify-content-between mt-5" :class="{'padding-bottom-85px': true}">
      <div class="h-100">
        <p class="m-0 f-s-15px text-center">Received By</p>
        <div>
          <p class="m-0 f-s-15px text-center">التوقيع مع الختم (Signed with stamp)</p>
        </div>
      </div>
      <div class="h-100">
        <p class="m-0 f-s-15px text-center">Authorized Signatory</p>
        <div>
          <p class="m-0 f-s-15px text-center">التوقيع مع الختم (Signed with stamp)</p>
        </div>
      </div>
    </div>

  </div>


</template>

<script>
export default {
  name: "footer-invoice-pdf",
  props: {innerData: {}, isBankingDetails: false, isMultiLanguageLabel: false, isSignature: false},
  data() {
    return {
      data: [],
    }
  },
  methods: {},
  created() {

  }
}
</script>

<style scoped>
.padding-bottom-85px{
  padding-bottom: 85px;
}
</style>